import React, { useState, useEffect } from 'react';
import InnerHeader from './InnerHeader';
import Select from 'react-select';
import { MdOutlineContentCopy } from "react-icons/md";
import { FaLongArrowAltRight } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import { base_url } from '../../config/config';
import Depositlist from './Depositlist';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import toast from 'react-hot-toast';
import { writeText } from 'clipboard-polyfill';
import dateFormat from 'dateformat';
import fundtransfer from '../../assets/img/ex/transfer.svg';

//madhkar  shukla 

const Deposit = () => {
    const [selectedCoin, setSelectedCoin] = useState(null); //setSelectedCoinName
    const [selectedCoinName, setSelectedCoinName] = useState(null)
    const [coinBalance, setCoinBalance] = useState();
    const [coinOptions, setCoinOptions] = useState([]);
    const [transactionHistroy, setTransactionHistroy] = useState([]);
    const [networkOptions, setNetworkOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0);
    const theme = localStorage.getItem('theme');
    const token = localStorage.getItem("token");
    const [receiverId, setReceiverId] = useState("");
    const [receiverAmount, setReceiverAmount] = useState(0);
    const [userEmail, setUserEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        getDepositTokens();
    }, []);


    useEffect(() => {
        handleRequest();
    }, [receiverId]);

    useEffect(() => {
        handleRequestCoin();
    }, [selectedCoinName])

    useEffect(() => {
        getDepositTokens();
        getNetworkId();
    }, [selectedCoin]);

    useEffect(() => {
        if (selectedCoin) {
            handleRequestCoin();
            fetchTransactionHistory();
        }
    }, [selectedCoin]);

    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        fetchTransactionHistory(skip);
    }, [currentPage, limit]);

    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const getDepositTokens = async () => {
        const token = localStorage.getItem('token')
        try {
            const coinResponse = await fetch(`${base_url}/token_list`, {

            });
            if (coinResponse.status === 401) {
                toast.dismiss();
                toast.error("Unauthorized access detected!")
                return;
            }
            const coinData = await coinResponse.json();
            const coinOptions = coinData.deposit_tokens.map((coin) => ({
                value: coin._id,
                label: coin.coinName,
                image: coin.image,
            }));

            setCoinOptions(coinOptions);
        } catch (error) {
            console.error('Error fetching deposit tokens:', error);
        }
    }

    const getNetworkId = async () => {
        const depositTokenResponse = await fetch(`${base_url}/deposit_token_details`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                tokenID: selectedCoin?.value,
            }),
        });

        const depositTokenData = await depositTokenResponse.json();

        if (depositTokenData.success === 1) {
            const tokenDetails = depositTokenData.tokenDetails[0];

            if (tokenDetails) {
                const networkDetails = tokenDetails.networkDetails;
                const networkOptions = networkDetails.map((network) => ({
                    value: network._id,
                    label: network.Network,
                }));
                setNetworkOptions(networkOptions);
            }
        }
    }


    const colourStyles = {
        option: (styles, { data, isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#0000003b' : null,
                color: 'red',
                fontSize: '13px'
            };
        }
    };

    const handleUser = async (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (!selectedCoin || !selectedCoin.value || !receiverId || !receiverAmount) {
            toast.dismiss();
            toast.error('Please fill in all the details');
            return;
        }

        const token = localStorage.getItem('token');
        // console.log({ coin: selectedCoin.value, receiverAmount, receiverId })
        try {
            const response = await fetch(`${base_url}/userFund_transfer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    receicerId: receiverId,
                    tokenId: selectedCoin.value,
                    amount: parseFloat(receiverAmount),
                }),
            });

            const data = await response.json();
            if (data.success === 1) {
                toast.dismiss()
                toast.success(data?.message);
                setReceiverId(null);
                setSelectedCoin(null);
                setReceiverAmount(null);

            } else {
                toast.dismiss();
                toast.error(data.message);
            }
        }
        catch (error) {
            toast.dismiss()
            toast.error('Error processing user fund transfer');

        }
    }

    const fetchTransactionHistory = async (skip = 0) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${base_url}/all_token_deposit_transactions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    limit,
                    skip,
                    transactionType: 'fund_transfer',
                }),
            });

            const data = await response.json();
            setTransactionHistroy(data.data);
            setTotal(data.total); // Update total records count
        } catch (error) {
            console.error('Error fetching transaction history:', error);
        }
    };
    // console.log('receiverId', receiverId)
    const handleRequest = async () => {
        const responsedata = await fetch(`${base_url}/auth/info`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                uid: receiverId,

            }),
        });
        let data = await responsedata.json();
        // console.log("Response----:", data);

        if (data.success === 1) {
            setUserEmail(data?.data?.email)
        } else {
            setUserEmail('')

        }
    }

    const handleRequestCoin = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${base_url}/useraccountbalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    tokenName: selectedCoinName,
                    wallet_type: 'main_wallet',
                }),
            });

            const data = await response.json();

            setCoinBalance(data.balance);
        } catch (error) {
            console.error('Error fetching coin balance:', error);
        }

    };

    const capitalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/[_-]/g, ' ') // Replace underscores or hyphens with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

    };

    return (
        <>
            <section className='deposit-page-main'>
                <div className='container'>
                    <div className='de-page-title'>
                        <h2>User Deals</h2>
                    </div>
                    <div className='dep-row'>
                        <div className='dep-left'>
                            <form onSubmit={(event) => handleUser(event)}>
                                <div className='dep-left-form'>

                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-2'><span>User Id</span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <label>User Id  </label>
                                            <input id="userinput" type="text" className='form-control w-100' autoComplete='off' value={submitted ? '' : receiverId} onChange={(e) => { setReceiverId(e.target.value) }} />
                                            <p className='useremail mt-1'>{userEmail}</p>
                                        </div>
                                    </div>

                                    <div className='dep-inner-flex mt-1'>
                                        <div className='label-div mt-1'><span>Select Coin</span></div>
                                        <div className='form-group input-div'>
                                            <label>Coin</label>
                                            <Select
                                                value={selectedCoin}
                                                onChange={(coin) => {
                                                    setSelectedCoin(coin);
                                                    // console.log('coin?.lebel', coin?.label);
                                                    setSelectedCoinName(coin?.label)
                                                }}
                                                options={coinOptions}
                                                styles={colourStyles}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        <img src={option.image} alt="coin" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                            <div className='labels-div mt-1'>
                                                <span>Main Wallet Balance :</span>
                                                {coinBalance !== null && selectedCoin && <span> {coinBalance} {selectedCoinName}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Enter Your Amount</span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <label>Amount</label>
                                            <input id="amountIn" type="number" className='form-control w-100' value={submitted ? '' : receiverAmount}
                                                onChange={(e) => { setReceiverAmount(e.target.value) }} min='0' />
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-4'>
                                        <div className='label-div mt-1'><span></span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <button type="submit" className='btn kyc-btn'>Submit</button>
                                        </div>
                                    </div>

                                    <div className='dep-inner-flex mt-4'>
                                        <div className='label-div mt-1'></div>
                                        <div className='form-group input-div input-div-1'>
                                            <div className='mini'>
                                                <p>Minimum 1 USDT is required in order to initiate the transaction</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className='dep-right'>
                            <img src={fundtransfer} alt="Deposit-image" className='w-100' />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='w-table-heading mt-5'>
                                <div className='over-pagetitle-01'>
                                    <h2>User Deals History</h2>
                                </div>
                            </div>
                            <div className='market-table'>
                                <table className="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Token Name</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionHistroy.length > 0 ? transactionHistroy?.map((row, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{row.tokenName}</td>
                                                        <td>{capitalizeText(row.mode)}</td>
                                                        <td>{row.amount}</td>
                                                        <td>{capitalizeText(row.from)}</td>
                                                        <td>{capitalizeText(row.to)}</td>
                                                        <td>{dateFormat(row.createdAt, "yyyy-mm-dd,hh:mm:ss TT")}</td>
                                                    </tr>
                                                </>
                                            )
                                        }) :
                                            (<tr className='text-center'>
                                                <td colSpan='6'>Data Not Found</td>
                                            </tr>)
                                        }

                                    </tbody>
                                </table>
                                <div className='market-table-footer'>
                                    {transactionHistroy.length > 0 ? (
                                        <>
                                            <div className='total-page'>
                                                <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                            </div>
                                            <div className='pagination-div'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                <GrFormPrevious />
                                                            </a>
                                                        </li>

                                                        <li className={`page-item`}>
                                                            <a className="page-link page-link1" >
                                                                {currentPage}
                                                            </a>
                                                        </li>

                                                        <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handleNextPage}>
                                                                <GrFormNext />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </>
                                    ) : (
                                        null
                                    )
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Deposit;