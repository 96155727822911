// import React from 'react'
// import { useEffect,useState } from 'react'
// import HistoryHeader from './HistoryHeader'
// import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
// import InnerHeader from './InnerHeader'

// const DepositHistory = () => {


//   return (
//     <>
//        <InnerHeader />
//       <section className='history-page'>
//         <div className='container'>
//           <HistoryHeader />

//           <div className='row'>
//             <div className='col-md-12'>
//               <div className='market-table mb-5'>
//                 <table class="table mt-table">
//                   <thead>
//                     <tr>
//                       <th scope="col">Id</th>
//                       <th scope="col">Token Name</th>
//                       <th scope="col">Amount</th>
//                       <th scope="col">Transaction Type</th>
//                       <th scope="col">Date</th>


//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>Bta</td>
//                       <td>.0293 </td>
//                       <td>02-04-2024</td>



//                     </tr>



//                   </tbody>
//                 </table>

//                 <div className='market-table-footer'>
//                   <div className='total-page'>
//                     <p>1-13 Of 13</p>
//                   </div>
//                   <div className='pagination-div'>
//                     <nav aria-label="Page navigation example">
//                       <ul class="pagination">
//                         <li class="page-item"><a class="page-link page-link1" href=""><GrFormPrevious /></a></li>
//                         <li class="page-item"><a class="page-link page-link1" href="">1</a></li>
//                         <li class="page-item"><a class="page-link page-link1" href=""><GrFormNext /></a></li>
//                       </ul>
//                     </nav>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>


//       </section>



//     </>
//   )
// }

// export default DepositHistory

import React, { useEffect, useState } from 'react';
import HistoryHeader from './HistoryHeader';
import InnerHeader from './InnerHeader';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { base_url } from '../../config/config';
import Skeleton from 'react-loading-skeleton';
const DepositHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page] = useState(1)




  useEffect(() => {
    setSkip((currentPage - 1) * limit);
  }, [currentPage, limit]);

  const totalPages = Math.ceil(total / limit);


  const handlePreviousPage = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  const handlePageClick = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${base_url}/all_token_deposit_transactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          transactionType: "deposit",
          Limit: limit,
          skip: skip

        })
      });
      // console.log("response--->", response);

      const data = await response.json();
      // console.log('all_token_deposit_transactions--', data);
      if (data.success === 1) {
        setTransactions(data.data);
        setTotal(data.total)
        setError(null);

      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, limit, skip]);

  const captalizeText = (text) => {
    if (!text) return '';
    return text
      .toLowerCase()
      .replace(/[_-]/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

  }

  // console.log('transactions------==', transactions.length);

  return (
    <>
      <InnerHeader />
      <section className='history-page'>
        <div className='container'>
          <HistoryHeader />
          <div className='row'>
            <div className='col-md-12'>
              <div className='market-table mb-5'>
                <table className="table mt-table">
                  <thead>
                    <tr>
                      <th scope="col">S No.</th>
                      <th scope="col">Id</th>
                      <th scope="col">Token Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transaction Type</th>
                      <th scope="col">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {transactions.length > 0 ? transactions?.map((transaction, index) => {
                        // console.log('transactiontransaction--------', transaction);
                        return (
                          <>
                            <tr key={index}>
                              <td>{(currentPage - 1) * limit + index + 1}</td>
                              {/* <td>{index + 1}</td> */}
                              <td>{transaction._id}</td>
                              <td>{transaction.tokenName}</td>
                              <td>{transaction.amount}</td>
                              <td>{captalizeText(transaction.transactionType)}</td>
                              <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                            </tr>
                          </>
                        )
                      }) :
                        (<tr className='text-center'>
                          <td colSpan='6'>Data Not Found</td>
                        </tr>)
                      }
                    </>
                  </tbody>
                </table>

                <div className='market-table-footer'>
                  {transactions.length > 0 ? (
                    <>
                      <div className='total-page'>
                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                      </div>
                      <div className='pagination-div'>
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                              <a className="page-link page-link1" onClick={handlePreviousPage}>
                                <GrFormPrevious />
                              </a>
                            </li>

                            <li className={`page-item`}>
                              <a className="page-link page-link1" >
                                {currentPage}
                              </a>
                            </li>

                            <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                              <a className="page-link page-link1" onClick={handleNextPage}>
                                <GrFormNext />
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>

                    </>
                  ) : (
                    null
                  )
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DepositHistory;

